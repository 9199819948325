import React from "react"
import styled from "styled-components"

const Panel = styled.footer`
  background-color: #23232c;
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
`

const Container = styled.footer`
  max-width: 1170px;
  margin: 0 auto;
  line-height: 1.75;
  padding: 6rem 1rem 2rem;
`

const LocationGrid = styled.section`
  display: grid;
  list-style: none;
  grid-column-gap: 24px;
  grid-row-gap: 36px;

  @media print, screen and (min-width: 50rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media print, screen and (min-width: 85rem) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Copyright = styled.small`
  display: block;
  margin-top: 4rem;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
`

const Address = styled.address`
  font-style: normal;
`

const OfficeRegion = styled.h4`
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
`

const OfficeCompanyName = styled.h5`
  font-size: inherit;
  margin-bottom: 0;
`

const OfficeAddressLines = styled.p`
  margin: 0;
`

const LegalLinks = styled.div``

const LegalLink = styled.a`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  & + & {
    margin-left: 24px;
  }
`

const offices = [
  {
    region: "Zurich",
    companyName: "additiv AG",
    addressLines: ["Riedtlistrasse 27", "8006 Zurich", "Switzerland"],
  },
  {
    region: "Singapore",
    companyName: "additiv Asia Pte. Ltd.",
    addressLines: ["71 Robinson Road", "#14-01", "Singapore 068895"],
  },
  {
    region: "Frankfurt",
    companyName: "additiv GmbH",
    addressLines: ["Kaiserhofstraße 16", "60313 Frankfurt", "Germany"],
  },
  {
    region: "Bucuresti",
    companyName: "additiv TECH SRL",
    addressLines: [
      "City Business Center",
      "Bulevardul Nerva Traian nr. 3",
      "Bucuresti",
      "Romania",
    ],
  },
]

const Footer = () => {
  const [copyrightYear] = React.useState(() => new Date().getFullYear())
  return (
    <Panel>
      <Container>
        <LocationGrid>
          {offices.map(office => (
            <Address key={office.companyName}>
              <OfficeRegion>{office.region}</OfficeRegion>
              <OfficeCompanyName>{office.companyName}</OfficeCompanyName>
              <OfficeAddressLines>
                {office.addressLines.map((line, idx) => (
                  <React.Fragment key={idx}>
                    <span>{line}</span>
                    {idx < office.addressLines.length && <br />}
                  </React.Fragment>
                ))}
              </OfficeAddressLines>
            </Address>
          ))}
        </LocationGrid>
        <Copyright>
          Copyright © {copyrightYear} additiv AG and/or its affiliates. All
          rights reserved.
        </Copyright>
        <LegalLinks>
          <LegalLink href="https://additiv.com/privacy-policy/">
            Privacy policy
          </LegalLink>
          <LegalLink href="https://additiv.com/imprint/">Imprint</LegalLink>
        </LegalLinks>
      </Container>
    </Panel>
  )
}

export default Footer
