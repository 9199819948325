import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Footer from "../components/Footer"
import heroSrc from "../images/hero.jpg"

const Container = styled.div`
  padding: 3rem 1rem;
  line-height: 1.5;
  max-width: 1170px;
  margin: 0 auto;
  display: grid;

  @media print, screen and (min-width: 50rem) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
  }

  li {
    margin-top: 1rem;
  }

  code {
    font-size: 0.929em;
    color: #3e4b8e;
    word-break: break-all;
    font-family: "Roboto Mono", monospace;
  }

  hr {
    height: 1px;
    border: none;
    border-bottom: 1px solid #ececec;
  }
`

const HeroImg = styled.img`
  max-width: 100%;
`

export default () => (
  <Layout>
    <SEO />
    <main className="main">
      <Container>
        <section>
          <h1>Welcome to DFS API</h1>
          <p>
            The DFS API catalog gives interested parties such as implementation
            partners, an insight into our API offering so that you can design
            and develop solutions based on APIs with focus on the
            functionalities required to build Hybrid Wealth Management
            solutions. The target audience is primarily software engineers and
            business analysts with knowhow in the finance industry and
            specifically the wealth domain.
          </p>
          <p>
            This portal will continuously grow to be the main resource for docs,
            guides, cookbooks and examples for additiv’s DFS API products and
            services.
          </p>
          <p>
            The DFS APIs are the backbone of the solution and are used by
            additiv's built DFS standard B2B and B2C web applications.
            Specifically, <a href="/wealth">the Wealth APIs</a> are the backbone
            of additiv’s Hybrid Wealth Management offering.
          </p>
        </section>
        <HeroImg src={heroSrc} />
        <section>
          <h2>Introduction</h2>
          <p>
            DFS services expose a RESTful API that aims to speaks the business
            domain language while <em>hiding</em> the business logic complexity
            from the consuming systems.
          </p>
          <h3>Multiple API hosts</h3>
          <p>
            DFS exposes multiple API groups, each serving different purposes.
            Different API groups might be under different hosts and a single
            group might be served by 1-n hosts.
          </p>
          <h3>API Gateway</h3>
          <p>
            In a microservices architecture, the client apps usually need to
            consume functionality from more than one microservice / host. If
            that consumption is performed directly, the client needs to handle
            multiple calls to microservice endpoints.{" "}
            <a href="https://docs.microsoft.com/en-us/dotnet/architecture/microservices/architect-microservice-container-applications/direct-client-to-microservice-communication-versus-the-api-gateway-pattern">
              (source: The API gateway pattern versus the Direct
              client-to-microservice communication)
            </a>
          </p>
          <p>
            DFS APIs continuously evolve and new microservices / hosts are
            introduced or existing microservices are updated. This, among many
            other reasons is why live APIs are accessed by client apps via an
            API gateway.
          </p>
          <p>
            For simplicity, this portal shows the endpoints in the standalone
            form and not under the API gateway.
          </p>
          <h2>Getting Started</h2>
          <p>
            All consumers using the DFS APIs (e.g. client applications, postman
            users etc.), must be uniquely identified and authorized to access
            DFS endpoints regardless if there is an authenticated person.
          </p>
          <ul>
            <li>
              Each consumer might be authorized to a specific sub set of
              endpoints based on the app needs and usage.
            </li>
          </ul>
          <p>
            This is achieved by a set of credentials provided to every consumer
            of the APIs
          </p>
          <ul>
            <li>
              <code>AppId</code> (i.e. username of the app)
            </li>
            <li>
              <code>APIKey</code> (i.e. secret / password of the app to match
              the <code>AppId</code>)
            </li>
          </ul>
          <p>
            Since DFS APIs are designed for multi tenancy, these credentials
            also allow the system to identify the tenant which the consumer is
            requesting to access. A single set of credentials authorizes a
            consumer to a single tenant.
          </p>
          <p>
            Endpoints that require such authorization (the vast majority)
            require an Authorization header (The <code>BasicAuth</code> token)
            in every call.
          </p>
          <ul>
            <li>
              The <code>AppId</code> and <code>ApiKey</code> must be sent on the
              Authorization header of the request encoded as Base64:{" "}
              <code>Base64(AppId:APIKey)</code>.
            </li>
            <li>
              For example{" "}
              <code>appId=&quot;xx9idmi1awVVAmJvCJxO3zURBx25K0&quot;</code> and{" "}
              <code>apiKey=&quot;maKQgiYl7HjbUmRubHIFJ3dOnQTFys&quot;</code>
            </li>
            <li>
              Encoding in base 64 of this string{" "}
              <code>
                &quot;xx9idmi1awVVAmJvCJxO3zURBx25K0:maKQgiYl7HjbUmRubHIFJ3dOnQTFys&quot;
              </code>{" "}
              results in this string{" "}
              <code>
                &quot;eHg5aWRtaTFhd1ZWQW1KdkNKeE8zelVSQngyNUswOm1hS1FnaVlsN0hqYlVtUnViSElGSjNkT25RVEZ5cw==&quot;
              </code>
            </li>
            <li>
              Adding the <code>&quot;Basic &quot;</code> prefix will give you
              the required authorization header value{" "}
              <code>
                &quot;Basic
                eHg5aWRtaTFhd1ZWQW1KdkNKeE8zelVSQngyNUswOm1hS1FnaVlsN0hqYlVtUnViSElGSjNkT25RVEZ5cw==&quot;
              </code>
            </li>
            <li>
              These credentials and specifically the <code>ApiKey</code> must be
              treated as secret and stored in a secure location.
            </li>
          </ul>
          <h3>Client Applications Access to APIs</h3>
          <p>
            Client apps are a common type of consumers of the DFS API and should
            follow some basic patterns.
          </p>
          <ul>
            <li>
              Access the APIs via the API gateway and not the API hosts
              directly.
            </li>
            <li>
              Do not store the API access credentials client side or in source
              code of the app even if they are obfuscated
            </li>
            <li>
              For standard web applications, such values should be stored in an
              app middleware on the server side which will also remove the need
              to do encoding client side.
            </li>
          </ul>
          <h3>Playing with the APIs</h3>
          <p>
            The API portal does not offer, at this point, ability to get access
            keys and execute calls against the end points. To get access to a
            running system with live APIs, please first{" "}
            <a href="https://www.additiv.com/contact-general/">contact us</a>{" "}
            with your specific needs
          </p>
          <h3>Not a developer?</h3>
          <p>
            <a href="https://www.additiv.com/contact-general/">Contact us</a> to
            schedule a demo of our standard Hybrid Wealth apps to learn what can
            be achieved with these APIs and how additiv can solve your business
            needs.
          </p>
          <p>
            The endpoints provided in this portal are not a guarantee of
            service. Partners authorized to access a live system will get
            regular updates which includes new and updated APIs. This portal is
            updated regularly but less frequently.
          </p>
          <h3>License &amp; Terms of Use</h3>
          <p>
            <em>
              The additiv DFS API catalog can only be used by additiv’s internal
              engineering teams or by licensed parties under a license agreement
              containing restrictions on use and disclosure. The DFS software
              and documentation, including the APIs, are protected by
              intellectual property laws. additiv’s objective is to focus on
              developing robust APIs as it designs and further develops the
              available services. The APIs may change in the course of
              architectural improvements, addition of capabilities, and
              optimization of performance. The API endpoints provided in this
              portal are not a guarantee of service. Parties authorized to
              access a live system will get regular updates which includes new,
              deprecated and updated APIs and in some cases, APIs might also be
              removed. Except as expressly permitted in individual license
              agreements or allowed by law, you may not use, copy, reproduce,
              translate, broadcast, modify, license, transmit, distribute,
              exhibit, perform, publish or display any part of the content on
              this site / document, in any form, or by any means.
            </em>
          </p>
          <hr />
          <p>
            Last update: <strong>DFS version 2019.4 CTP W45</strong>
          </p>
          <p>
            Passionate about creating beautiful software that change the future
            of Banking?{" "}
            <a href="https://additiv.com/careers/">
              Join additiv’s engineering teams.
            </a>
          </p>
        </section>
      </Container>
    </main>
    <Footer />
  </Layout>
)
